import React from "react";
import Layout from "../layout/Layout";
import { staticPage } from "../../config/staticPage";
import LoginForm from "../sections/login/LoginForm";
import { Link } from "gatsby";

const LoginPage = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-heading-section">
          <div className="container">
            <h1 className="page-title">Sign In</h1>
          </div>
        </div>
      </div>

      <div className="login-section">
        <div className="container">
          <div className="row flex vertical-middle flex-wrap">
            <div className="col login-form-block">
              <LoginForm />
            </div>
            <div className="col login-info-block">
              <div className="panel">
                <div className="panel-header">
                  <h2 className="panel-title">New Customer?</h2>
                </div>
                <div className="panel-body">
                  <p className="new-customer-intro">
                    Create an account with us and you'll be able to:
                  </p>

                  <ul className="new-customer-fact-list">
                    <li>Check out faster</li>
                    <li>Save multiple shipping addresses</li>
                    <li>Access your order history</li>
                    <li>Track new orders</li>
                    <li>Save items to your Wish List</li>
                  </ul>

                  <Link className="button" to="/create-account">
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "login") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

LoginPage.Layout = Layout
export default LoginPage;
